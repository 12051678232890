<template>
  <div v-frag>
    <v-card class='rounded-md' elevation='3'>
      <v-container class='pa-5'>
        <v-row no-gutters>
          <v-col cols='12' lg='3' md='3' sm='12' xl='3' xs='12'>
            <v-card
              :class="small ? 'rounded-lg' : 'rounded-r-0 rounded-lg'"
              color='primary'
              elevation='0'
              height='100%'
              width='100%'
            >
              <v-row
                align='center'
                class='fill-height'
                justify='center'
                no-gutters
              >
                <v-icon class='white--text' x-large>mdi-charity</v-icon>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols='12' lg='9' md='9' sm='12' xl='9' xs='12'>
            <v-card
              :class="small ? 'rounded-lg' : 'rounded-l-0 rounded-lg'"
              elevation='0'
              style='border: 2px solid #ecf1f9'
            >
              <v-data-table
                :headers='headers'
                :items='desserts'
                :loading='loading'
                class='elevation-0'
                hide-default-footer
              >
                <template v-slot:[`item.hourDays`]='{ item }'>
                  <div class='primary--text'>
                    {{ item.hourDays }}
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import charity from '../../../services/charity'

export default {
  async mounted() {
    await this.initialLoad()
  },
  computed: {
    small() {
      if (
        this.$vuetify.breakpoint.name == 'xs' ||
        this.$vuetify.breakpoint.name == 'sm'
      ) {
        return true
      } else {
        return false
      }
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Volunteer hours',
          align: 'start',
          sortable: false,
          value: 'volunteerHours',
          divider: true
        },
        {
          text: 'Hours',
          align: 'start',
          sortable: false,
          value: 'hours',
          divider: true
        },
        {
          text: '8 Hr Days',
          align: 'start',
          sortable: false,
          value: 'hourDays',
          divider: true
        }
      ],
      desserts: [],
      loading: false
    }
  },
  methods: {
    async initialLoad() {
      try {
        const res = await charity.getTableVolunteerOverview()
        this.desserts = res
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }
  }
}
</script>
