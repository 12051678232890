<template>
  <div v-frag>
    <dialog-edit-profile/>
    <v-container fluid>
      <Alert/>
      <v-row>
        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-card>
            <v-container fluid>
              <v-row align='center'>
                <v-col cols='12' lg='3' md='3' sm='6' xl='3' xs='12'>
                  <v-avatar color='primary' size='200'>
                    <!-- <img :src="logo"/> -->
                    <v-img :src='logo' contain/>
                  </v-avatar>
                </v-col>
                <v-col cols='12' lg='3' md='3' sm='6' xl='3' xs='12'>
                  <div
                    class='
                      text-h5
                      font-weight-black
                      grey--text
                      text--darken-1 text-center
                    '
                  >
                    {{ name }}
                  </div>
                </v-col>
                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <v-btn
                    :width="small ? '100%' : ''"
                    class='white--text body-1 text-capitalize font-weight-bold'
                    color='primary'
                    elevation='0'
                    outlined
                    style='background-color: white; float: right'
                    x-large
                    @click='openDialog'
                  >
                    Edit Charity Profile
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>

        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-row>
            <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
              <volunteer-data-overview/>
            </v-col>
            <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
              <donation-data-overview/>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-card elevation='3'>
            <table-volunteering-opportunities/>
          </v-card>
        </v-col>

        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-card elevation='3'>
            <table-open-fundraising-needs/>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div style='display: none'>{{ alert.show }}</div>
  </div>
</template>

<script>
import DialogEditProfile from './components/DialogEditProfile'
import TableVolunteeringOpportunities from './components/TableVolunteeringOpportunities'
import TableOpenFundraisingNeeds from './components/TableOpenFundraisingNeeds'
import VolunteerDataOverview from './components/VolunteerDataOverview'
import DonationDataOverview from './components/DonationDataOverview'
import Alert from '../../components/Alert'
import charity from '../../services/charity'

export default {
  components: {
    DialogEditProfile,
    TableVolunteeringOpportunities,
    TableOpenFundraisingNeeds,
    VolunteerDataOverview,
    DonationDataOverview,
    Alert
  },
  async mounted() {
    await this.initialLoad()
  },
  computed: {
    small() {
      if (
        this.$vuetify.breakpoint.name == 'xs' ||
        this.$vuetify.breakpoint.name == 'sm'
      ) {
        return true
      } else {
        return false
      }
    },
    alert() {
      if (this.$store.getters.alert.response) {
        this.initialLoad()
      }
      return this.$store.getters.alert
    }
  },
  data() {
    return {
      logo: '',
      name: ''
    }
  },
  methods: {
    async initialLoad() {
      try {
        const res = await charity.getProfile()
        this.logo = res.logo
        this.name = res.name
      } catch (e) {
        //   empty
      }
    },
    openDialog() {
      let text = 'Edit Profile'
      this.$store.dispatch('crud', {
        dialog: true,
        title: text,
        id: null
      })
    }
  }
}
</script>
