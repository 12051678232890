<template>
  <div v-frag>
    <v-dialog
      v-model='crud.dialog'
      max-width='1000px'
      persistent
      scrollable
      transition='dialog-bottom-transition'
    >
      <v-card>
        <v-card-title>
          <span class='headline'>{{ crud.title }}</span>
        </v-card-title>
        <v-card-text class='py-2 px-4'>
          <v-container>
            <v-form ref='form' v-model='valid' lazy-validation>
              <v-row>
                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <p class='font-weight-bold'>Address*</p>
                  <v-text-field
                    v-model='inputs.address'
                    :rules='addressRules'
                    dense
                    outlined
                    placeholder='13001 Wild Turkey Dr'
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <p class='font-weight-bold'>City*</p>
                  <v-text-field
                    v-model='inputs.city'
                    :rules='cityRules'
                    dense
                    outlined
                    placeholder='Austin'
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <p class='font-weight-bold'>State*</p>
                  <v-text-field
                    v-model='inputs.state'
                    :rules='stateRules'
                    dense
                    outlined
                    placeholder='Texas'
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <p class='font-weight-bold'>Zip Code*</p>
                  <v-text-field
                    v-model='inputs.zipCode'
                    :rules='zipCodeRules'
                    dense
                    outlined
                    placeholder='78652'
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <p class='font-weight-bold'>Phone*</p>
                  <v-text-field
                    v-model='inputs.phone'
                    :rules='phoneRules'
                    dense
                    outlined
                    placeholder='214-985-5683'
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <p class='font-weight-bold'>Email*</p>
                  <v-text-field
                    v-model='inputs.email'
                    :rules='emailRules'
                    dense
                    outlined
                    placeholder='email@email.com'
                    required
                    type='email'
                  ></v-text-field>
                </v-col>

                <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                  <p class='font-weight-bold'>Mission*</p>
                  <v-text-field
                    v-model='inputs.mision'
                    :rules='misionRules'
                    dense
                    outlined
                    placeholder='Mission'
                    required
                    type='text'
                  ></v-text-field>
                </v-col>

                <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                  <p class='font-weight-bold'>Description*</p>
                  <wysiwyg
                    v-model='inputs.description'
                    :rules='descriptionRules'
                  />
                </v-col>

                <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                  <v-row>
                    <v-col cols='12' lg='3' md='3' sm='12' xl='3' xs='12'>
                      <v-avatar color='primary' size='150'>
                        <v-img :src='imagePreview'></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col cols='12' lg='9' md='9' sm='12' xl='9' xs='12'>
                      <p class='font-weight-bold'>Image*</p>
                      <v-file-input
                        v-model='fileInput'
                        accept='image/png, image/jpeg, image/bmp'
                        chips
                        dense
                        outlined
                        placeholder='Add a file or drop it here'
                        prepend-icon=''
                        prepend-inner-icon='mdi-camera'
                        show-size
                        truncate-length='50'
                        @change='selectFile'
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class='d-flex direction-row justify-center'>
          <v-btn
            :disabled='loading'
            class='px-14 mx-4 font-weight-bold'
            color='primary'
            large
            outlined
            @click='cancel'
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled='loading'
            :loading='loading'
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='validateAddress'
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model='dialogCrop'
      max-width='500px'
      persistent
      scrollable
      transition='dialog-bottom-transition'
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <clipper-fixed
            ref='clipper'
            :round='true'
            :src='image'
          ></clipper-fixed>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class='px-14 mx-4 font-weight-bold'
            color='primary'
            large
            outlined
            @click='cancelCrop'
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled='loading'
            :loading='loading'
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='cropImage'
          >
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model='dialogAddress' max-width='500px'>
      <v-card class='d-flex flex-column align-start pa-2'>
        <v-card-title class='text-center mb-5'>
          <v-icon
            class='mr-4 white--text' color='warning' large left
          >mdi-alert
          </v-icon
          >
          Warning!
        </v-card-title>
        <v-card-subtitle>
          <b
          >The entered address may not belong to a real address, do you want
            to continue with the operation?</b
          >
        </v-card-subtitle>
        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            outlined
            @click='dialogAddress = false'
          >
            Cancel
          </v-btn
          >
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='updated()'
          >
            Confirm
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import charity from '../../../services/charity'
import googleMapsService from '../../../services/googleMaps'

export default {
  computed: {
    img() {
      return 'http://localhost:8080/' + this.imagePreview
    },
    crud() {
      this.initialLoad()
      return this.$store.getters.crud
    }
  },
  data: () => ({
    inputs: {
      address: '',
      city: '',
      state: '',
      zipCode: '',
      phone: '',
      email: '',
      mision: '',
      description: '',
      image: '',
      fileType: ''
    },
    dialogAddress: false,
    image: '',
    imagePreview: '',
    loading: false,
    valid: false,
    itemsProfile: [],
    itemsEntity: [],
    addressRules: [(v) => !!v || 'Address is required'],
    cityRules: [(v) => !!v || 'City is required'],
    stateRules: [(v) => !!v || 'State is required'],
    zipCodeRules: [(v) => !!v || 'Zip code is required'],
    phoneRules: [
      v => !!v || 'Phone is required',
      v => /^\d+$/.test(v) || "Value must be a number",
      v => v.length <= 10 || "Value must be equal to 10 characters",
      v => v.length >= 10 || "Value must be equal to 10 characters",
    ],
    misionRules: [(v) => !!v || 'Mission is required'],
    descriptionRules: [(v) => !!v || 'Description is required'],
    emailRules: [
      (v) => !!v || 'Email Address is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    dialogCrop: false,
    fileInput: null
  }),
  methods: {
    async initialLoad() {
      if (this.$store.getters.crud.dialog) {
        const res = await charity.getProfile()
        this.inputs = {
          address: res.address,
          city: res.city,
          state: res.state,
          zipCode: res.zip_code,
          phone: res.contact_phone,
          email: res.general_email,
          mision: res.mission,
          description: res.description,
          image: res.logo
        }
        this.image = res.logo
        this.imagePreview = res.logo
        this.fileInput = this.formatAvatar(res.logo)
      }
    },
    selectFile(file) {
      if (file) {
        this.fileInput = file
        this.inputs.image = file
        this.inputs.fileType = file.type
        this.image = URL.createObjectURL(file)
        this.dialogCrop = true
      } else {
        this.inputs.image = null
        this.inputs.fileType = null
        this.image = ''
      }
    },
    formatAvatar(value) {
      if (typeof value == 'string' && value !== '') {
        let fileName = value.split('/')
        fileName = fileName[fileName.length - 1]
        return new File([`${ fileName }`], `${ fileName }`, {
          type: 'text/plain'
        })
      } else {
        return ''
      }
    },
    async updated() {
      this.loading = true
      if (this.$refs.form.validate()) {
        const res = await charity.updatedProfile(this.inputs)
        this.closeDialog()
        this.dialogAddress = false
        await this.$store.dispatch('alert', {
          show: true,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        await this.$store.dispatch('image')
        this.loading = false
      } else {
        this.loading = false
      }
    },
    async validateAddress() {
      const data = {
        address: this.inputs.address,
        state: this.inputs.state,
        city: this.inputs.city,
        zip_code: this.inputs.zipCode,
        country: 'USA'
      }
      const res = await googleMapsService.validateAddress(data)
      if (res.statusMcba) {
        await this.updated()
      } else {
        this.dialogAddress = true
      }
    },
    cropImage() {
      this.$refs.clipper.clip()
        .toBlob((blob) => {
          this.inputs.image = blob
          this.image = URL.createObjectURL(blob)
          this.imagePreview = URL.createObjectURL(blob)
          this.dialogCrop = false
        })
    },
    async cancel() {
      this.closeDialog()
    },
    closeDialog() {
      this.$refs.form.reset()
      this.$store.dispatch('crud', {
        dialog: false,
        title: '',
        id: null
      })
    },
    cancelCrop() {
      this.dialogCrop = false
      this.fileInput = null
    }
  }
}
</script>
