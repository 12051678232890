<template>
  <div v-frag>
    <!-- <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
      <v-card elevation="0"> -->
    <v-container fluid>
      <v-card-title>
        <v-row align='center' align-content='center' justify='center'>
          <v-col cols='12' lg='8' md='8' sm='12' xl='8' xs='12'>
            <div class='text-h5 font-weight-black grey--text text--darken-1'>
              {{ sections.volunteer_opportunities_profile || 'Volunteer Opportunities title' }}
            </div>
          </v-col>
          <v-col cols='12' lg='4' md='4' sm='12' xl='4' xs='12'>
            <v-btn
              class='white--text body-1 text-capitalize font-weight-bold'
              color='primary'
              elevation='0'
              outlined
              style='background-color: white; float: right'
              to='/opportunities'
              x-large
            >
              Manage Opportunities
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-card
          class='rounded-lg'
          elevation='0'
          style='border: 2px solid #ecf1f9'
        >
          <v-row>
            <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
              <v-data-table
                :headers='headers'
                :items='desserts'
                :items-per-page='10'
                :loading='loading'
                :options.sync='options'
                :search='search'
                :server-items-length='totalDesserts'
                class='elevation-0 data-table-border-radius'
                hide-default-footer
              >
                <template v-slot:[`item.spots`]='{ item }'>
                  <div :class="item.colormember + '--text'">
                    {{ item.membersCount }} / {{ item.volunteers_required }}
                  </div>
                </template>
                <template v-slot:[`item.member_company`]='{ item }'>
                  <v-btn color='primary' small @click='detailCompany(item.id)'
                  >Detail
                  </v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-container>
    <!-- </v-card>
    </v-col> -->
    <v-dialog v-model='dialog' max-width='500px' persistent>
      <v-card>
        <v-card-title>
          <span class='headline'>Detail</span>
        </v-card-title>
        <v-card-text class='py-2 px-4'>
          <v-data-table
            :headers='headerDetail'
            :items='detail'
            elevation='0'
            hide-default-header
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions class=''>
          <v-btn
            block
            class='font-weight-bold primary--text elevation-0'
            color='white'
            large
            @click='closeDialog'
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import charity from '../../../services/charity'
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState('cmsStore', ['sections'])
  },
  mounted() {
    this.loadInitial()
  },
  data() {
    return {
      headers: [
        {
          text: 'Description',
          align: 'start',
          sortable: true,
          value: 'description',
          divider: true
        },
        {
          text: 'Open dates from',
          align: 'start',
          sortable: true,
          value: 'date',
          divider: true
        },
        {
          text: 'Hs.',
          align: 'start',
          sortable: true,
          value: 'hours_required',
          divider: true
        },
        {
          text: 'Time',
          align: 'start',
          sortable: true,
          value: 'time',
          divider: true
        },
        {
          text: 'Address',
          align: 'start',
          sortable: true,
          value: 'address',
          divider: true
        },
        {
          text: 'Spots',
          align: false,
          sortable: false,
          value: 'spots',
          divider: true
        },
        {
          text: 'Member Company',
          align: false,
          sortable: false,
          value: 'member_company',
          divider: true
        }
      ],
      desserts: [],
      totalDesserts: 0,
      search: '',
      loading: false,
      options: {},
      dialog: false,
      detail: [],
      headerDetail: [
        {
          text: 'Name',
          align: false,
          sortable: false,
          value: 'name',
          divider: true
        }
      ]
    }
  },
  methods: {
    async loadInitial() {
      const res = await charity.getVolunteering()
      this.totalDesserts = res.length
      this.desserts = res
    },
    async detailCompany(id) {
      try {
        this.detail = []
        const res = await charity.detailCompany(id)
        this.detail = res
        this.dialog = true
      } catch (e) {
        // empty
      }
    },
    closeDialog() {
      this.detail = []
      this.dialog = false
    }
  }
}
</script>
