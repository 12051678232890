<template>
  <div v-frag>
    <!-- <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
      <v-card elevation="0"> -->
    <v-container fluid>
      <v-card-title>
        <v-row align='center' align-content='center' justify='center'>
          <v-col cols='12' lg='8' md='8' sm='12' xl='8' xs='12'>
            <div class='text-h5 font-weight-black grey--text text--darken-1'>
              {{ sections.fundraising_needs || 'Fundraising Needs title' }}
            </div>
          </v-col>
          <v-col cols='12' lg='4' md='4' sm='12' xl='4' xs='12'>
            <v-btn
              class='white--text body-1 text-capitalize font-weight-bold'
              color='primary'
              elevation='0'
              outlined
              style='background-color: white; float: right'
              to='/fundraising-needs'
              x-large
            >
              Manage Fundraising Needs
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-card
          class='rounded-lg'
          elevation='0'
          style='border: 2px solid #ecf1f9'
        >
          <v-row>
            <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
              <v-data-table
                :headers='headers'
                :items='desserts'
                :items-per-page='10'
                :loading='loading'
                :options.sync='options'
                :search='search'
                :server-items-length='totalDesserts'
                class='elevation-0 data-table-border-radius'
                hide-default-footer
              >
                <template v-slot:[`item.spots`]='{ item }'>
                  <div :class="item.colormember + '--text'">
                    {{ item.membersCount }} / {{ item.volunteers_required }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-container>
    <!-- </v-card>
    </v-col> -->
  </div>
</template>

<script>
import charity from '../../../services/charity'
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState('cmsStore', ['sections'])
  },
  watch: {
    options: {
      async handler() {
        await this.initialLoad()
      },
      deep: true
    },
    search: {
      async handler() {
        this.options.search = this.search
        await this.initialLoad()
      },
      deep: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date',
          divider: true
        },
        {
          text: 'Need',
          align: 'start',
          sortable: true,
          value: 'need',
          divider: true
        },
        {
          text: 'Funds needed',
          align: 'start',
          sortable: true,
          value: 'fundsNeeded',
          divider: true
        },
        {
          text: 'Amount Raised',
          align: 'start',
          sortable: true,
          value: 'amountRaised',
          divider: true
        },
        {
          text: 'Dead line',
          align: false,
          sortable: false,
          value: 'deadline',
          divider: true
        }
      ],
      desserts: [],
      totalDesserts: 0,
      search: '',
      loading: false,
      options: {}
    }
  },
  methods: {
    async initialLoad() {
      this.loading = true
      try {
        const res = await charity.getTableFundraisingNeeds(this.options)
        this.totalDesserts = res.totalDesserts
        this.desserts = res.desserts
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }
  }
}
</script>
