<template>
  <div v-frag>
    <div v-if="user.rol == 'Charity'" v-frag>
      <overview-charity/>
    </div>
  </div>
</template>

<script>
import OverviewCharity from './charity/Overview.vue'

export default {
  name: 'Home',
  components: {
    OverviewCharity
  },
  computed: {
    user() {
      return this.$store.getters.user
    }
  }
}
</script>

<style scoped>
.dashboard {
  display: grid;
  grid-template-columns: 1fr 5fr;
  height: 100vh;
  width: 100vw;
  border-radius: 0;
}
.content {
  background-color: white;
  margin: 0;
  padding: 0;
}
</style>
